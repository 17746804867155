import { t } from "i18next";
import React from "react";
import { Row, Col, Button } from "reactstrap";

const footer = (props) => {
  const logo = props.data.Logo;
  const home = props.data.HomeLink;
  const contact = props.data.ContactLink;
  const privacy = props.data.PrivacyLink;
  const terms = props.data.TermsLink;

  const onFooterNavigate = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="w-100 ordo-ui-footer">
      <Row className="mt-2">
        <Col className="col-5">
          <Button
            onClick={() => {
              props.onValidateLink(() => onFooterNavigate(contact));
            }}
            className="btn btn-block ordo-btn-link"
          >
            <span className="text-white">{t("footer_contact")}</span>
          </Button>
        </Col>
        <Col className="col-2 text-center">
          <Button
            onClick={() => {
              props.onValidateLink(() => onFooterNavigate(home));
            }}
            className="btn btn-block ordo-btn-link"
          >
            <img
              id="hygglig-logo"
              width="auto"
              className="align-right"
              src={logo}
              alt="Hygglig"
            />
          </Button>
        </Col>
        <Col className="col-5">
          <Row>
            <Col className="col-6 text-end">
              <Button
                onClick={() => {
                  props.onValidateLink(() => onFooterNavigate(privacy));
                }}
                className="btn btn-block ordo-btn-link"
              >
                <span className=" text-white">{t("footer_privacy")}</span>
              </Button>
            </Col>

            <Col className="col-6 text-end">
              <Button
                onClick={() => {
                  props.onValidateLink(() => onFooterNavigate(terms));
                }}
                className="btn btn-block ordo-btn-link"
              >
                <span className="text-white">{t("footer_terms")}</span>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default footer;
