import React from "react";
import { Container, Button, Col, Row, Modal, ModalBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import PlaceholderHelper from "../placeholders/placeholders";

const SubMerchantInfo = (props) => {
  const [showContact, setShowContact] = useState(false);
  const [subMerchant, setSubmerchant] = useState({
    SubMerchantId: 0,
    CompanyName: "",
    Email: "",
    StreetAddress: "",
    City: "",
    PostalCode: "",
    Logo: "",
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (props.data) {
      setSubmerchant(props.data);
    }
  }, [props.data]);

  const merchantDetails = (
    <div className="ordo-ui-lbl-l">
      <Col className="ordo-ui-nav-title col-12">{subMerchant.CompanyName}</Col>
      <Col className="ordo-ui-nav-subtitle col-12">
        {subMerchant.StreetAddress}
      </Col>
      <Col className="ordo-ui-nav-subtitle col-12">
        {subMerchant.PostalCode + " " + subMerchant.City}
      </Col>
    </div>
  );

  const contactStoreButton = (
    <Button
      onClick={() => props.onValidateLink(() => setShowContact(!showContact))}
      className="ordo-ui-btn"
    >
      {t("contact_store")}
    </Button>
  );

  const subMerchantInfoStyle = {
    height: subMerchant.Logo ? "80px" : "auto",
  };

  return (
    <Container
      id="submerchant-info"
      fluid
      className="ordo-ui-component mt-0 mb-4 pt-2 mt-0 mt-lg-4"
    >
      {props.data === null ? (
        <Row>
          <Col>
            <h2 className="text-danger">ERROR !</h2>
          </Col>
        </Row>
      ) : props.data ? (
        <Row>
          <Col
            className={`col-12 text-center text-lg-start mt-3 pb-4 pb-lg-0 ${
              subMerchant.Logo ? "" : "d-lg-block d-none"
            }`}
            style={subMerchantInfoStyle}
          >
            {subMerchant.Logo ? (
              <img
                className="merchant-logo img-fluid align-top d-none d-lg-inline"
                src={subMerchant.Logo}
                alt={subMerchant.CompanyName}
              />
            ) : (
              ""
            )}

            {subMerchant.Logo ? (
              <img
                className="merchant-logo img-fluid align-top d-lg-none"
                src={subMerchant.Logo}
                alt={subMerchant.CompanyName}
              />
            ) : (
              ""
            )}

            <div className="d-lg-inline-block d-none ps-3">
              {merchantDetails}
            </div>
          </Col>

          <Col className="col-12 ordo-ui-lbl-m d-lg-block d-none text-end pe-0">
            {contactStoreButton}
          </Col>

          <Col>
            <Row className="d-block-inline d-lg-none">
              <Col className="col-6 p-4">{merchantDetails}</Col>
              <Col className="col-6 text-end align-self-end pe-0">
                {contactStoreButton}
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <PlaceholderHelper data="merchant" />
      )}

      <Modal
        isOpen={showContact}
        toggle={() => setShowContact(!showContact)}
        backdrop="static"
        className="ordo-ui-modal  "
      >
        <ModalBody className="pt-4 px-4 mb-5">
          <Row className="mb-3">
            <Col className="ordo-ui-title mb-3">{t("contact_store")}</Col>
          </Row>

          <Row>
            <Col className="ordo-ui-label">
              <span className="h4">{subMerchant.CompanyName}</span>
            </Col>
            <Col className="ordo-ui-lbl-rd col-12">
              <span className="h6">{subMerchant.StreetAddress}</span>
            </Col>
            <Col className="ordo-ui-lbl-rd col-12">
              <span className="h6">
                {subMerchant.PostalCode + " " + subMerchant.City}
              </span>
            </Col>
          </Row>

          <Row className="my-4 mb-5">
            <Col className="h6 col-12 ordo-ui-danger">
              <button
                id="merchant-email-link"
                onClick={() =>
                  props.onValidateLink(() =>
                    window.open(
                      `mailto:${subMerchant.Email}?Subject=${t(
                        "question_for_order"
                      )} ${props.orderNum}`
                    )
                  )
                }
                className="btn btn-block"
              >
                {subMerchant.Email}
              </button>
            </Col>
            <Col className="ordo-ui-lbl-rd col-12">
              <span className="h6">{subMerchant.PhoneNumber}</span>
            </Col>
          </Row>
        </ModalBody>

        <Button
          onClick={() => setShowContact(!showContact)}
          className="ordo-ui-btn btn-block"
        >
          {t("close")}
        </Button>
      </Modal>
    </Container>
  );
};

export default SubMerchantInfo;
