import React from "react";
import { Row, Col, Container } from "reactstrap";
import Balance from "./outstanding-balance/balance";
import Events from "./events-history/eventsHistory";
import OrderDetails from "./order-details/orderDetails";
import SubMerchantInfo from "./subMerchantInfo/subMerchantInfo.jsx";
import PDF from "./invoice-download/invoiceDownload";

const contentGrid = (props) => {
  return (
    <main>
      <Container fluid className="dashboard px-0">
        <SubMerchantInfo
          data={props.merchantData}
          logo={props.logo}
          onValidateLink={props.onValidateLink}
          orderNum={props.data.OrderNumber}
        />

        <Row className="h-100 mx-0">
          <Col className="col-12 col-lg-4 h-100 px-0 pe-lg-3">
            {/* OUTSTANDING BALANCE SECTION*/}
            <Row className="ordo-ui-balance-container mx-0">
              <Col className="mb-4 mb-lg-4 px-0">
                <Balance data={props.data} />
              </Col>
            </Row>
            {/* PDF SECTION */}
            <Row className="ordo-ui-pdf-container mx-0">
              <Col className="mb-4 mb-lg-0 px-0">
                <PDF
                  data={props.data.OrderNumber}
                  onValidateLink={props.onValidateLink}
                  status={props.status}
                />
              </Col>
            </Row>
          </Col>
          {/* ORDER DETAILS SECTION */}
          <Col className="col-12 col-lg-4 mb-4 h-100 px-0 px-lg-2">
            <OrderDetails
              data={props.data}
              onValidateLink={props.onValidateLink}
            />
          </Col>
          {/* EVENTS SECTION */}
          <Col className="col-12 col-lg-4 mb-4 h-100 px-0 ps-lg-3">
            <Events
              data={props.data.Event}
              onValidateLink={props.onValidateLink}
            />
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default contentGrid;
