import axios from "axios";

const baseURL = process.env.REACT_APP_ORDO_FRONTEND_API;

const getDashboardLink = async (id, status, setData) => {
  await axios
    .get(`${baseURL}/api/Link/${id}?status=${status}`)
    .then((response) => {
      window.location.replace(response.data);
    })
    .catch((error) => {
      console.error({
        Message: "Unable to fetch Dashboard Link.",
        Error: error.message,
        URL: error.config.url,
      });
      
      setData(true);
    });
};

const getOrder = async (id, setData, setLoaded, cultureInfo) => {
  await axios
    .get(`${baseURL}/api/Order/${id}`, {
      headers: {
        CultureInfo: cultureInfo,
      },
    })
    .then((response) => {
      setData(response.data);
      setLoaded(true);
    })
    .catch((error) => {
      setLoaded(true);

      console.error({
        Message: "Unable to fetch Order Data.",
        Error: error.message,
        URL: error.config.url,
      });
    });
};

const getMerchant = async (id, setData, setLoaded) => {
  await axios
    .get(`${baseURL}/api/Merchant/${id}`)
    .then((response) => {
      setData(response.data);
      setLoaded(true);
    })
    .catch((error) => {
      setData(null);
      setLoaded(true);

      console.error({
        Message: "Unable to fetch Merchant Data.",
        Error: error.message,
        URL: error.config.url,
      });
    });
};

export const ordoApiService = {
  GetDashboardLink: getDashboardLink,
  GetMerchant: getMerchant,
  GetOrder: getOrder,
};
