import "./App.css";
import "./ordo-ui.style.css";
import "./components/subMerchantInfo/subMerchantInfo.css";
import ContentGrid from "./components/contentGrid";
import React, { useState, useEffect } from "react";
import { ordoApiService } from "./services/OrdoApiService";
import Footer from "./components/footer/footer";
import FooterMobile from "./components/footer/footerMobile";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Buffer } from "buffer";
import logoRed from "./images/hnm-logo.png";
import logoWhite from "./images/hnm-logo-white.png";
import logoLarge from "./images/hnm-logo-big.png";
import footerLogo from "./images/hygglig-logo.png";
import EnglishIcon from "./images/lang-en.png";
import SwedishIcon from "./images/lang-se.png";
import { t } from "i18next";

const App = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const currentLanguage =
    (queryParams.get("lng") ? queryParams.get("lng").toLowerCase() : "") ===
    "en"
      ? "en"
      : "se";
  const orderNumber = queryParams.get("order_number") ?? "";
  const linkData = queryParams.get("data");
  const [language] = useState(currentLanguage);
  const [toggle, setToggle] = useState(true);
  const [merchantData, setMerchantData] = useState();
  const [orderData, setOrderData] = useState({});
  const [status, setStatus] = useState(queryParams.get("status") ?? "");

  const [merchantDataLoaded, setMerchantDataLoaded] = useState(false);
  const [orderDataLoaded, setOrderDataLoaded] = useState(false);

  const [linkExpired, setLinkExpired] = useState(false);
  const [linkInvalid, setLinkInvalid] = useState(false);
  const [logoId, setLogoId] = useState(0);

  const footerData = {
    Logo: footerLogo,
    HomeLink: "https://www.hygglig.com/",
    ContactLink: "https://www.hygglig.com/kontakta-oss/",
    PrivacyLink:
      "https://www.marginalen.se/privat/kundservice/personuppgifter/",
    TermsLink: "https://www.hygglig.com/anvandarvillkor/",
  };

  const validateLink = (callback) => {
    try {
      if (orderNumber && linkData) {
        let jsonData = JSON.parse(Buffer.from(linkData, "base64"));
        setStatus(jsonData.Status);
        if (
          parseInt(orderNumber) === jsonData.OrderNumber &&
          jsonData.Timestamp
        ) {
          if (checkIfLinkExpired(jsonData)) {
            setLinkExpired(true);
          } else if (!orderDataLoaded) {
            loadDashboardData();
          } else if (callback) {
            callback();
          }
        } else {
          setLinkInvalid(true);
        }
      } else {
        ordoApiService.GetDashboardLink(orderNumber, status, setLinkInvalid);
      }
    } catch (e) {
      setLinkInvalid(true);
    }
  };

  const checkIfLinkExpired = (data) => {
    const expirationValue = data.ExpirationValue || 24;
    const computedSeconds = data.ExpirationType
      ? data.ExpirationType === "hour"
        ? 3600 * 1000
        : 60 * 1000
      : 3600 * 1000;

    if (
      Math.abs(new Date() - new Date(data.Timestamp)) / computedSeconds >=
      expirationValue
    ) {
      return true;
    }

    return false;
  };

  const loadDashboardData = () => {
    const languageQuery = queryParams.get("lng");
    const lngCodes = ["en", "se"];
    if (languageQuery && lngCodes.indexOf(languageQuery.toLowerCase()) > -1) {
      ordoApiService.GetOrder(
        orderNumber,
        setOrderData,
        setOrderDataLoaded,
        t("language_code")
      );
    } else {
      queryParams.delete("lng");

      window.location.href = `${
        window.location.origin
      }?${queryParams.toString()}&lng=se`;
    }
  };

  useEffect(() => {
    setLogoId(queryParams.get("logo"));

    validateLink();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const checkIfDataLoaded = () => {
      if (orderDataLoaded && !merchantDataLoaded) {
        if (orderData.MerchantId) {
          ordoApiService.GetMerchant(
            orderData.MerchantId,
            setMerchantData,
            setMerchantDataLoaded
          );
        } else {
          setMerchantData(null);
          setMerchantDataLoaded(true);
        }
      }

      if (merchantDataLoaded) {
        setMerchantData((merchant) => {
          if (logoId) merchant.Logo = getSubMerchantLogo(logoId);
          return merchant;
        });
      }
    };

    checkIfDataLoaded();
    // eslint-disable-next-line
  }, [linkExpired, linkInvalid, orderDataLoaded, merchantDataLoaded]);

  const getSubMerchantLogo = (id) => {
    id = parseInt(id);

    if (id === 1) return logoRed;

    if (id === 2) return logoWhite;

    if (id === 3) return logoLarge;

    return null;
  };

  const onLanguageChange = (language) => {
    const currentLanguage = queryParams.get("lng");

    if (currentLanguage !== language) {
      queryParams.delete("lng", currentLanguage);

      window.location.href = `${
        window.location.origin
      }?${queryParams.toString()}&lng=${language}`;
    }
  };

  return (
    <div className="App">
      {linkExpired || linkInvalid ? (
        <Modal
          isOpen={linkExpired || linkInvalid}
          centered={true}
          backdrop="static"
        >
          <ModalBody className="pt-4 px-4">
            <Row>
              <Col className="h1 text-center ordo-ui-danger">
                {linkExpired ? t("link_expired") : t("link_invalid")}
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      ) : (
        <div>
          <Dropdown
            id="ddl-language"
            isOpen={!toggle}
            toggle={() => setToggle(!toggle)}
          >
            <DropdownToggle caret>
              <img
                src={language === "se" ? SwedishIcon : EnglishIcon}
                width={30}
                alt="Swedish"
              />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                active={language === "se"}
                onClick={() => {
                  onLanguageChange("se");
                }}
              >
                <img src={SwedishIcon} width={30} alt="Swedish" />
              </DropdownItem>
              <DropdownItem
                active={language === "en"}
                onClick={() => {
                  onLanguageChange("en");
                }}
              >
                <img src={EnglishIcon} width={30} alt="English" />
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <ContentGrid
            data={orderData}
            merchantData={merchantData}
            onValidateLink={validateLink}
            status={status}
          />
          <Footer
            data={footerData}
            onValidateLink={validateLink}
            className="d-lg-block"
          />
          <FooterMobile
            data={footerData}
            onValidateLink={validateLink}
            className="d-lg-none"
          />
        </div>
      )}
    </div>
  );
};

export default App;
