import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import AxiosInstance from "axios";

const loadResources = async (locale) => {
  return await AxiosInstance()
    .get("/translate-data/get", { params: { lang: locale } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const backendOptions = {
  loadPath: "{{lng}}|{{ns}}",
  request: (options, url, payload, callback) => {
    try {
      const [lng] = url.split("|");
      loadResources(lng).then((response) => {
        callback(null, {
          data: response,
          status: 200,
        });
      });
    } catch (e) {
      console.error(e);
      callback(null, {
        status: 500,
      });
    }
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(Backend)
  .use(LanguageDetector)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)

    //lng: "se", // if you're using a language detector, do not define the lng option
    resources: {
      se: {
        translation: {
          contact_store: "Kontakta butiken",
          current_status: "Nuvarande status",
          view_full_history: "Visa full historik",
          close: "Stäng",
          status_due: "Förfallen",
          status_ongoing: "Pågående",
          status_settled: "Avslutad",
          receive_email_notif: "Få e-postnotifikationer",
          label_specification: "Specifikation",
          label_due_date: "Förfallodatum",
          label_order_date: "Orderdatum",
          label_order_number: "Ordernummer",
          label_total: "Totalt",
          label_left_to_pay: "Kvar att betala",
          label_pdf_content: "Klicka för att ladda ned faktura.",
          label_manage_invoice: "Hantera Faktura",
          language_code: "sv-SE",
          btn_label_specification: "Visa specifikation",
          btn_download: "Ladda ned",
          footer_privacy: "Sekretess",
          footer_terms: "Villkor",
          footer_contact: "Kontakt",
          link_invalid: "Länken är ogiltig",
          link_expired: "Länken har utgått",
          question_for_order: "Fråga om order",
        },
      },
      en: {
        translation: {
          contact_store: "Contact the Store",
          current_status: "Current Status",
          view_full_history: "View full history",
          close: "Close",
          status_due: "Due",
          status_ongoing: "Ongoing",
          status_settled: "Settled",
          receive_email_notif: "Receive email notifications",
          label_specification: "Specification",
          label_due_date: "Due date",
          label_order_date: "Order date",
          label_order_number: "Order number",
          label_total: "Total",
          label_left_to_pay: "Left to pay",
          label_pdf_content: "Click to download Invoice.",
          label_manage_invoice: "Manage Invoice",
          language_code: navigator.language,
          btn_label_specification: "Show specification",
          btn_download: "Download",
          footer_privacy: "Privacy",
          footer_terms: "Terms",
          footer_contact: "Contact",
          link_invalid: "Link is invalid",
          link_expired: "Link has expired",
          question_for_order: "Question for Order",
        },
      },
    },
    backend: backendOptions,
    fallbackLng: "se",
    detection: {
      order: [
        "querystring",
        "path",
        "htmlTag",
        "navigator",
        "cookie",
        "localStorage",
        "sessionStorage",
        "subdomain",
      ],
    },
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });
