import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Card, CardBody, CardTitle, Row } from "reactstrap";
import "../../ordo-ui-custom.css";
import { t } from "i18next";
import { ordoFormatter } from "../formaters/ordoFormatter";
import PlaceholderHelper from "../placeholders/placeholders";

const BalanceInfo = (props) => {
  const [balance, setBalance] = useState("");

  const dueDate = ordoFormatter.FormatDate(props.data.DueDate);
  const currentStatus = props.data.CurrentStatus;

  useEffect(() => {
    setBalance(
      `${ordoFormatter.FormatNumber(props.data.FinalPrice, true)} (SEK)`
    );
  }, [props.data]);

  return (
    <Card className="ordo-ui-component">
      {Object.keys(props.data).length > 0 ? (
        <CardBody>
          <Row>
            <div className="balance-card">
              <CardTitle className="ordo-ui-title pt-2 text-uppercase">
                <span>{t("label_left_to_pay")}</span>
              </CardTitle>
              <div className="mb-3">
                <span className="ordo-ui-label">{balance}</span>
              </div>
            </div>
          </Row>

          <Row>
            <div className="balance-card">
              <CardTitle className="ordo-ui-title pt-2 text-uppercase">
                <span>{t("label_due_date")}</span>
              </CardTitle>

              <div className="mb-3">
                <span
                  className={
                    (currentStatus === t("status_settled")
                      ? "ordo-ui-success "
                      : currentStatus === t("status_due")
                      ? "ordo-ui-danger-light "
                      : "") + "ordo-ui-label"
                  }
                >
                  {currentStatus}
                </span>
              </div>

              {currentStatus !== t("status_settled") && (
                <div className="mb-3">
                  <span className="ordo-ui-lbl-sbi">{dueDate}</span>
                </div>
              )}
            </div>
          </Row>
        </CardBody>
      ) : (
        <PlaceholderHelper data="balance" />
      )}
    </Card>
  );
};

export default BalanceInfo;
