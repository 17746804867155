const formatDate = (date) => {
  if (date) {
    let newDate = new Date(date);
    const offset = newDate.getTimezoneOffset();
    newDate = new Date(newDate.getTime() - offset * 60 * 1000);

    return newDate.toISOString().split("T")[0];
  }

  return "";
};

const formatNumber = (number, hideZeroes) => {
  let num = parseFloat(number);
  let formattedNumber = "";

  if (hideZeroes && num % 1 === 0) {
    formattedNumber = Number(num.toFixed(0)).toLocaleString("en");
  } else {
    formattedNumber = Number(num.toFixed(2)).toLocaleString("en", {
      minimumFractionDigits: 2,
    });
  }

  formattedNumber = formattedNumber.replace(/,/g, " ");
  formattedNumber = formattedNumber.replace(".", ",");
  return formattedNumber + " kr";
};

export const ordoFormatter = {
  FormatDate: formatDate,
  FormatNumber: formatNumber,
};
