import React from "react";
import { Row, Col, List } from "reactstrap";

const Timeline = (props) => {
  const data = props.data ?? [];

  function getTimelineClass(eventColor) {
    switch (eventColor) {
      case "Green":
        return "ordo-ui-success";
      case "Red":
        return "ordo-ui-danger";
      default:
        return "";
    }
  }

  return (
    <List className="ordo-ui-events mb-5" style={{ position: "relative" }}>
      {data.map((item, index) => (
        <li
          key={index}
          className={
            getTimelineClass(item.EventColor) + " ordo-ui-event-item pb-4"
          }
        >
          <Row>
            <Col className="col-12 ordo-ui-event-label ps-0 pb-2">
              <div dangerouslySetInnerHTML={{ __html: item.Description }} />
            </Col>
            <Col className="col-12 ordo-ui-event-date mb-2 ps-0">
              {item.FormattedDateTime}
            </Col>
          </Row>
        </li>
      ))}
    </List>
  );
};

export default Timeline;
