import React, { useState, useEffect, useMemo } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
} from "reactstrap";
import "./orderDetails.css";
import { useTranslation } from "react-i18next";
import { ordoFormatter } from "../formaters/ordoFormatter";
import PlaceholderHelper from "../placeholders/placeholders";

const OrderDetails = (props) => {
  const orderData = useMemo(() => {
    return {
      OrderNumber: props.data.OrderNumber,
      OrderDate: ordoFormatter.FormatDate(props.data.OrderDate),
      Articles: props.data.Articles ?? [],
      ReminderFees: props.data.ReminderFees ?? [],
      Credits: props.data.OrderCredits ?? [],
      TotalOrderAmout: props.data.TotalPrice,
      FinalTotalOrderAmount: props.data.FinalPrice,
    };
  }, [props.data]);

  const { t } = useTranslation();

  const totalOrderAmount = orderData.TotalOrderAmout;
  const finalAmount = orderData.FinalTotalOrderAmount;
  const ocrNumber = props.data.OCRNumber;
  const dateString = orderData.OrderDate;

  const [orderDetails, setOrderDetails] = useState([]);
  const [reminderFees, setReminderFees] = useState([]);
  const [orderCredits, setOrderCredits] = useState([]);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOrderDetails(orderData.Articles);
    setReminderFees(orderData.ReminderFees);
    setOrderCredits(orderData.Credits);
  }, [orderData]);

  const displayOrders = (data) => {
    if (orderDetails.length > data.length) {
      data.push({
        ArticleName: "....",
      });
    }

    return data.map((item, key) => (
      <Row key={key} className="py-1 ordo-ui-row">
        <Col xs="12">
          <span className="pe-2">
            {`${item.ArticleName} ${
              item.Quantity > 1 ? `( ${item.Quantity} st)` : ""
            }`}
          </span>

          <span className="text-end">
            {item.ArticleName === "...."
              ? item.ArticleName
              : ordoFormatter.FormatNumber(item.TotalPrice)}
          </span>
        </Col>
      </Row>
    ));
  };

  const displayReminderFees = () => {
    return reminderFees.map((item, index) => (
      <Row key={index} className="ordo-ui-row">
        <Col xs="12">
          <span className="py-1">{item.Description}</span>

          <span className="text-end py-1">
            {ordoFormatter.FormatNumber(item.Amount)}
          </span>
        </Col>
      </Row>
    ));
  };

  const displayOrderCredits = (data) => {
    if (orderCredits.length > data.length) {
      data.push({
        Description: "....",
      });
    }

    return data.map((item, index) => (
      <Row className="py-1 ordo-ui-row" key={index}>
        <Col xs="12">
          <span className="pe-2">{item.Description}</span>

          <span className="text-end">
            {item.Description === "...."
              ? item.Description
              : `-${ordoFormatter.FormatNumber(item.Amount)}`}
          </span>
        </Col>
      </Row>
    ));
  };

  const getTotalSection = () => {
    return (
      <Row className="pt-2 ordo-ui-row">
        <Col xs="12" className="ordo-ui-lbl-sb">
          <span className="mb-2 pe-2">{t("label_total")}</span>

          <span className="text-end">
            {ordoFormatter.FormatNumber(totalOrderAmount)}
          </span>
        </Col>
      </Row>
    );
  };

  const getLeftToPaySection = () => {
    return (
      <Col xs="12">
        <Row className="ordo-ui-lbl-sbd py-2 ordo-ui-row">
          <Col xs="12">
            <span className="pe-2">{t("label_left_to_pay")}</span>

            <span className="text-end">
              {ordoFormatter.FormatNumber(finalAmount, false)}
            </span>
          </Col>
        </Row>
      </Col>
    );
  };

  return (
    <div className="ordo-ui-component" style={{ height: "100%" }}>
      {props.data.OrderNumber ? (
        <>
          <Card className="border-bottom" style={{ height: "25%" }}>
            <CardBody>
              <Row>
                <Col xs="12">
                  <CardTitle className="ordo-ui-title text-uppercase pt-2">
                    {t("label_specification")}
                  </CardTitle>
                </Col>
              </Row>

              <Row className="py-1 ordo-ui-row">
                <Col xs="12">
                  <span className="pe-2 ordo-ui-lbl-rd">
                    {t("label_order_date")}
                  </span>

                  <span className="text-end ordo-ui-lbl-rd">{dateString}</span>
                </Col>
              </Row>

              <Row className="py-1 ordo-ui-row">
                <Col xs="12">
                  <span className="pe-2 ordo-ui-lbl-rd">
                    {t("label_order_number")}
                  </span>

                  <span className="text-end ordo-ui-lbl-rd">{ocrNumber}</span>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card style={{ height: "75%" }}>
            <CardBody className="py-2 overflow-auto">
              <Row className="ordo-ui-lbl-m border-bottom">
                <Col xs="12">
                  {orderDetails.length > 0
                    ? displayOrders([...orderDetails].splice(0, 5))
                    : ""}
                </Col>
              </Row>

              {getTotalSection()}

              {orderCredits.length > 0 || reminderFees.length > 0 ? (
                <Row>
                  <Col xs="12" className="border-bottom ordo-ui-lbl-m">
                    {displayReminderFees()}
                    {displayOrderCredits([...orderCredits].splice(0, 5))}
                  </Col>

                  {getLeftToPaySection()}
                </Row>
              ) : (
                ""
              )}
            </CardBody>

            <Button
              className="ordo-ui-btn btn-block bottom-0"
              onClick={() => props.onValidateLink(() => setOpen(!open))}
            >
              {t("btn_label_specification")}
            </Button>
          </Card>

          <Modal
            id="view-full-specification-modal"
            isOpen={open}
            toggle={() => setOpen(!open)}
            backdrop="static"
            className="ordo-ui-modal"
          >
            <ModalBody className="my-3 mx-1 mx-sm-4">
              <Row>
                <Col xs="12" className="ordo-ui-title mb-3">
                  {t("label_specification")}
                </Col>
              </Row>

              <Row style={{ maxHeight: "500px", overflowY: "scroll" }}>
                <Col xs="12">
                  <Row className="ordo-ui-lbl-m border-bottom">
                    <Col xs="12">{displayOrders(orderDetails)}</Col>
                  </Row>

                  {getTotalSection()}

                  {orderCredits.length > 0 || reminderFees.length > 0 ? (
                    <Row className="ordo-ui-lbl-m">
                      <Col xs="12" className="border-bottom">
                        {displayReminderFees()}
                        {displayOrderCredits(orderCredits)}
                      </Col>

                      {getLeftToPaySection()}
                    </Row>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </ModalBody>

            <Button
              onClick={() => props.onValidateLink(() => setOpen(!open))}
              className="ordo-ui-btn btn-block"
            >
              {t("close")}
            </Button>
          </Modal>
        </>
      ) : (
        <PlaceholderHelper data="specification" />
      )}
    </div>
  );
};

export default OrderDetails;
