import { Card, CardBody, Row, Col } from "reactstrap";
import { Placeholder } from "react-bootstrap";

const PlaceholderHelper = (props) => {
  if (props.data === "merchant") {
    return (
      <>
        <Row className="d-none d-lg-block">
          <Col>
            <Row className="ps-1 py-3">
              <Col className="col-lg-2">
                <Placeholder animation="glow">
                  <Placeholder lg={12} size="lg" style={{ height: "100px" }} />
                </Placeholder>
              </Col>

              <Col className="col-lg-2">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="lg" />
                  <Placeholder xs={8} size="xs" />
                  <Placeholder xs={6} size="xs" />
                </Placeholder>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="d-md-block d-lg-none">
          <Col>
            <Row className="py-3 ps-1">
              <Col className="col-12 pb-3">
                <Row>
                  <Col className="offset-4 col-4">
                    <Placeholder animation="glow">
                      <Placeholder
                        xs={12}
                        size="lg"
                        style={{ height: "80px", width: "100%" }}
                      />
                    </Placeholder>
                  </Col>
                </Row>
              </Col>

              <Col className="col-4 pb-1">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="lg" />
                  <Placeholder xs={8} size="xs" />
                  <Placeholder xs={6} size="xs" />
                </Placeholder>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  if (props.data === "balance") {
    return (
      <CardBody className="align-items-center justify-content-center">
        <Row>
          <Col>
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={6} size="md" className="mb-4 mt-2" />
            </Placeholder>
          </Col>
        </Row>

        <Row>
          <Col>
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={8} size="lg" className="mb-4" />
            </Placeholder>
          </Col>
        </Row>

        <Row>
          <Col>
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={6} size="md" className="mb-4" />
            </Placeholder>
          </Col>
        </Row>

        <Row>
          <Col>
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={4} size="lg" className="mb-4" />
            </Placeholder>
          </Col>
        </Row>

        <Row>
          <Col>
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={3} size="xs" className="mb-3" />
            </Placeholder>
          </Col>
        </Row>
      </CardBody>
    );
  }

  if (props.data === "invoice") {
    return (
      <CardBody className="align-items-center justify-content-center">
        <Row>
          <Col>
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={4} size="lg" className="mb-3" />
            </Placeholder>
          </Col>
        </Row>

        <Row>
          <Col>
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={4} size="md" />
            </Placeholder>
          </Col>
        </Row>
      </CardBody>
    );
  }

  if (props.data === "specification") {
    return (
      <CardBody className="p-3">
        <Row>
          <Col className="col-12 pt-2 pb-3">
            <Placeholder animation="glow">
              <Placeholder xs={12} size="lg" />
            </Placeholder>
          </Col>

          <Col className="col-12 pt-2 pb-3">
            <Row>
              <Col className="col-6">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="md" />
                </Placeholder>
              </Col>
              <Col className="col-6">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="md" />
                </Placeholder>
              </Col>
              <Col className="col-6">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="md" />
                </Placeholder>
              </Col>
              <Col className="col-6">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="md" />
                </Placeholder>
              </Col>
            </Row>
          </Col>

          <Col className="col-12 pt-2 pb-3">
            <Row>
              <Col className="col-8">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="md" />
                </Placeholder>
              </Col>
              <Col className="col-4">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="md" />
                </Placeholder>
              </Col>
              <Col className="col-8">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="md" />
                </Placeholder>
              </Col>
              <Col className="col-4">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="md" />
                </Placeholder>
              </Col>
              <Col className="col-8">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="md" />
                </Placeholder>
              </Col>
              <Col className="col-4">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="md" />
                </Placeholder>
              </Col>
            </Row>
          </Col>

          <Col className="col-12 pt-2 pb-3">
            <Row>
              <Col className="col-8">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="md" />
                </Placeholder>
              </Col>
              <Col className="col-4">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="md" />
                </Placeholder>
              </Col>
              <Col className="col-8">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="md" />
                </Placeholder>
              </Col>
              <Col className="col-4">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="md" />
                </Placeholder>
              </Col>
            </Row>
          </Col>

          <Col className="col-12 pt-2 pb-3">
            <Row>
              <Col className="col-8">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="md" />
                </Placeholder>
              </Col>
              <Col className="col-4">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="md" />
                </Placeholder>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    );
  }

  if (props.data === "events") {
    return (
      <CardBody className="align-items-center justify-content-center">
        <Row>
          <Col>
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={4} className="mb-4 mt-2" />
            </Placeholder>
          </Col>
        </Row>

        <Row>
          <Col>
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={4} size="lg" className="mb-4" />
            </Placeholder>
          </Col>
        </Row>

        <Placeholder as={Card.Text} animation="glow">
          <Placeholder xs={12} />
          <Placeholder xs={6} size="xs" className="mb-2" />
        </Placeholder>

        <Placeholder as={Card.Text} animation="glow">
          <Placeholder xs={12} />
          <Placeholder xs={6} size="xs" className="mb-2" />
        </Placeholder>

        <Placeholder as={Card.Text} animation="glow">
          <Placeholder xs={12} />
          <Placeholder xs={6} size="xs" />
        </Placeholder>
      </CardBody>
    );
  }

  return <></>;
};

export default PlaceholderHelper;
