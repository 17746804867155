import { t } from "i18next";
import React from "react";
import { Card, CardBody, Button, CardTitle } from "reactstrap";
import PlaceholderHelper from "../placeholders/placeholders";

const InvoiceDownload = (props) => {
  const baseURL = process.env.REACT_APP_MYPAGES_URL;

  function createInvoicePDF(orderNumber, status) {
    window.open(`${baseURL}/CheckoutBuyer/Createpdf/${orderNumber}?status=${status}`);
  }

  return (
    <Card className="ordo-ui-component">
      {props.data ? (
        <>
          <CardBody>
            <CardTitle className="ordo-ui-title pt-2 text-uppercase">
              {t("label_manage_invoice")}
            </CardTitle>
            <span className="ordo-ui-dark-label mt-2">
              {t("label_pdf_content")}
            </span>
          </CardBody>

          <Button
            onClick={() =>
              props.onValidateLink(() => createInvoicePDF(props.data, props.status))
            }
            className="ordo-ui-btn btn-block bottom-0"
          >
            {t("btn_download")}
          </Button>
        </>
      ) : (
        <PlaceholderHelper data="invoice" />
      )}
    </Card>
  );
};

export default InvoiceDownload;
