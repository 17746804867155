import React from "react";
import Timeline from "./timeline";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
} from "reactstrap";
import { useState } from "react";
import PlaceholderHelper from "../placeholders/placeholders";

function EventsHistory(props) {
  const eventData = props.data ?? {
    EventStatusLabel: "",
    OrdoEventTypes: [],
  };
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <Card className="ordo-ui-component" style={{ overflowY: "hidden" }}>
      {props.data === null ? (
        <CardBody className="d-flex align-items-center justify-content-center">
          <h2 className="text-danger">ERROR !</h2>
        </CardBody>
      ) : eventData.EventStatus ? (
        <div>
          <CardBody className="pt-4 px-4">
            <CardTitle className="ordo-ui-title">STATUS</CardTitle>
            <CardSubtitle className="ordo-ui-label mb-3">
              <label
                className={
                  eventData.EventStatus === t("status_settled")
                    ? "ordo-ui-success"
                    : ""
                }
              >
                {eventData.EventStatus}
              </label>
            </CardSubtitle>

            <Row className="ordo-ui-event-padding">
              {/* <Col className="form-check ms-2 mb-3">
                <input
                  className="form-check-input ordo-ui-checkbox"
                  type="checkbox"
                  value=""
                  id="chkbox-receive-notifs"
                  defaultChecked
                />
                <label
                  id="lbl-receive-notif"
                  className="form-check-label"
                  htmlFor="chkbox-receive-notifs"
                  style={{ verticalAlign: "text-bottom" }}
                >
                  {t("receive_email_notif")}
                </label>
              </Col> */}
            </Row>
            <Row style={{ paddingLeft: "9px" }}>
              <Col>
                <Timeline data={[...eventData.OrdoEventTypes].splice(0, 5)} />
              </Col>
            </Row>
          </CardBody>

          <CardBody className="px-0">
            <Button
              onClick={() => props.onValidateLink(() => setOpen(!open))}
              className="ordo-ui-btn btn-block position-absolute bottom-0"
            >
              {t("view_full_history")}
            </Button>
          </CardBody>

          <Modal
            isOpen={open}
            toggle={() => setOpen(!open)}
            className="ordo-ui-modal"
            backdrop="static"
          >
            <ModalBody className="pt-4 px-4">
              <Row>
                <Col xs="12" className="ordo-ui-title">
                  STATUS
                </Col>
                <Col xs="12" className="ordo-ui-label mb-3">
                  <label
                    className={
                      eventData.EventStatus === t("status_settled")
                        ? "ordo-ui-success"
                        : ""
                    }
                  >
                    {eventData.EventStatus}
                  </label>
                </Col>
              </Row>
              <Row style={{ paddingLeft: "9px" }}>
                <Col
                  className="overflow-auto"
                  style={{ overflowY: "auto", maxHeight: "500px" }}
                >
                  {open ? <Timeline data={eventData.OrdoEventTypes} /> : ""}
                </Col>
              </Row>
            </ModalBody>

            <Button
              onClick={() => props.onValidateLink(() => setOpen(!open))}
              className="ordo-ui-btn btn-block"
            >
              {t("close")}
            </Button>
          </Modal>
        </div>
      ) : (
        <PlaceholderHelper data="events" />
      )}
    </Card>
  );
}

export default EventsHistory;
